<template>
  <div v-if="(userLevel === 1)">
    <synthesis-analysisone></synthesis-analysisone>
  </div>
  <div v-else>
    <synthesis-analysistwo></synthesis-analysistwo>
  </div>
</template>

<script>
import {
  getCurrentInstance,
} from "vue";
import SynthesisAnalysisone from "./SynthesisAnalysis2.vue";
import SynthesisAnalysistwo from "./SynthesisAnalysis3.vue";
export default {
  name: "SynthesisAnalysis",
  components: {SynthesisAnalysisone, SynthesisAnalysistwo},
  inject: ['isModifyStyle'],
  setup() {
    // 可以当this来使用
    const { proxy } = getCurrentInstance();

    const userLevel = JSON.parse(localStorage.getItem("userInfo")).chargingMerchant.level

    return {
      userLevel
    };
  },
};
</script>